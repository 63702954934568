* {
  margin: 0;
  box-sizing: border-box;
}

html {
  background: #222;
  width: 100vw;
  height: 100%;
  /* height: -webkit-fill-available; */
  overflow: hidden;
}

/* html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
} */

body, #root {
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family:"Avenir Next", Helvetica, Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


